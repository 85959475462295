import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { FaChartBar, FaUsers, FaUserCircle, FaCogs, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase'; // Import Firebase auth

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Sign out the user using Firebase Authentication
      console.log("User logged out");
      navigate('/login'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Dynamic active button styling
  const isActive = (path) => location.pathname === path;

  return (
    <AppBar position="static" className="navbar" style={{ backgroundColor: '#0f1e35' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        {/* Navbar Buttons */}
        <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          <Button
            startIcon={<FaChartBar />}
            style={{
              color: isActive('/waitlist-dashboard') ? '#FFD700' : '#ffffff', // Highlight active tab
              textTransform: 'none',
            }}
            onClick={() => navigate('/waitlist-dashboard')}
          >
            Waitlist Dashboard
          </Button>
          <Button
            startIcon={<FaUsers />}
            style={{
              color: isActive('/brand-ambassador') ? '#FFD700' : '#ffffff',
              textTransform: 'none',
            }}
            onClick={() => navigate('/brand-ambassador-dashboard')}
          >
            Brand Ambassador
          </Button>
          <Button
            startIcon={<FaUserCircle />}
            style={{
              color: isActive('/profile') ? '#FFD700' : '#ffffff',
              textTransform: 'none',
            }}
            onClick={() => navigate('/profile')}
          >
            Profile
          </Button>
          <Button
            startIcon={<FaCogs />}
            style={{
              color: isActive('/settings') ? '#FFD700' : '#ffffff',
              textTransform: 'none',
            }}
            onClick={() => navigate('/settings')}
          >
            Settings
          </Button>
          <IconButton
            onClick={handleLogout}
            style={{ color: '#ffffff' }}
            title="Logout"
          >
            <FaSignOutAlt />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;