import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  CircularProgress, AppBar, Toolbar, Typography, IconButton, TextField, Box, Container, Pagination, Card, CardContent,
} from '@mui/material';
import { ExitToApp as ExitToAppIcon, SaveAlt as SaveAltIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { API_BASE_URL } from '../constants/APIConstants';
import * as XLSX from 'xlsx';
import { auth } from '../firebase';
import { format, parseISO } from 'date-fns'; // Add date-fns for date formatting
// Function to convert ISO date to readable format
export const formatDate = (isoDate) => {
  if (!isoDate) return 'N/A';
  try {
    const cleanDate = isoDate.split('[')[0]; // Remove timezone if present
    return format(parseISO(cleanDate), 'MMM dd, yyyy h:mm a') + ' EST'; // Format: Nov 13, 2024 8:51 PM EST
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
};
const WaitlistDashboard = () => {
  const [currentTab, setCurrentTab] = useState('pending');
  const [tableData, setTableData] = useState([]);
  const [tabCounts, setTabCounts] = useState({ pending: 0, approved: 0, rejected: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
  const itemsPerPage = 10;
  const navigate = useNavigate();

  

  const handleViewProfile = (accountId) => {
    const selectedAccount = tableData.find((account) => account.accountId === accountId);
    navigate(`/AccountDetails/${accountId}`, {
      state: {
        account: selectedAccount,
        portfolioImages: selectedAccount.portfolioCollections,
      },
    });
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      alert('An error occurred while logging out. Please try again.');
    }
  };

  const fetchData = async (status) => {
    setLoading(true);
    const apiUrl = `${API_BASE_URL}/api/admin/waitlist/accounts?status=${status}`;

    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(apiUrl, { headers: { Authorization: `Bearer ${token}` } });

      const transformedData = response.data.map((item) => ({
        accountId: item.accountDetails?.accountId || 'N/A',
        name: item.accountDetails?.accountName || 'N/A',
        email: item.accountDetails?.accountEmail || 'N/A',
        contact: item.accountDetails?.accountPhone || 'N/A',
        portfolioImages: item.portfolioCollections?.some(
          (collection) => Array.isArray(collection.portfolioDocuments) && collection.portfolioDocuments.length > 0
        )
          ? 'Yes'
          : 'No',
        isEmailVerified: item.emailVerified || false,
        accountDescription: item.accountDetails?.accountDescription || 'N/A',
        socialMediaLinked: item.accountDetails?.socialMediaLinked,
        accountCreatedAt: formatDate(item.accountDetails?.accountCreatedAt), // Convert to readable format
        accountProfileURL: item.accountDetails?.accountProfileURL || 'N/A',
        accountCategory: convertToTitleCase(item.accountDetails?.accountCategory) || 'N/A',
        accountSubCategory: convertToTitleCase(item.accountDetails?.accountSubCategory) || 'N/A',
      }));

      setTableData(transformedData);
      setTabCounts((prevCounts) => ({
        ...prevCounts,
        [status]: transformedData.length,
      }));

      localStorage.setItem(`waitlistData-${status}`, JSON.stringify(transformedData));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = () => {
    // Filter out 'accountId' and 'accountProfileURL' from the table data
    const filteredData = tableData.map(({ accountId, accountProfileURL, ...rest }) => rest);
  
    // Convert column headers to title case
    const headers = Object.keys(filteredData[0] || {}).map(convertToTitleCase);
    const dataWithHeaders = [headers, ...filteredData.map(Object.values)];
  
    // Create a worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    const workbook = XLSX.utils.book_new();
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Waitlist');
  
    // Write the workbook to a file
    XLSX.writeFile(workbook, 'WaitlistData.xlsx');
  };
  

  useEffect(() => {
    const cachedData = localStorage.getItem(`waitlistData-${currentTab}`);
    if (cachedData) {
      setTableData(JSON.parse(cachedData));
    } else {
      fetchData(currentTab);
    }
  }, [currentTab]);

  const filteredData = tableData.filter((row) =>
    row.name.toLowerCase().includes(searchText.toLowerCase()) ||
    row.email.toLowerCase().includes(searchText.toLowerCase())
  );

  const currentPageData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Container maxWidth="lg" style={{ marginTop: '20px' }}>
      <AppBar position="static" style={{ backgroundColor: '#0f1e35', marginBottom: '20px' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h5" style={{ color: '#ffffff' }}>Waitlist Dashboard</Typography>
          <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
            <Button
              startIcon={<RefreshIcon />}
              onClick={() => fetchData(currentTab)}
              style={{ color: '#ffffff', textTransform: 'none' }}
            >
              Refresh
            </Button>
            <TextField
              variant="outlined"
              placeholder="Search by Name or Email"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              size="small"
              style={{ width: '300px', backgroundColor: '#ffffff', borderRadius: '4px' }}
            />
            <Button
              startIcon={<ExitToAppIcon />}
              onClick={handleLogout}
              style={{ color: '#ffffff', textTransform: 'none' }}
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Status Cards */}
      <Box display="flex" justifyContent="space-between" mb={3}>
        {['pending', 'approved', 'rejected'].map((status) => (
          <Card
            key={status}
            onClick={() => setCurrentTab(status)}
            style={{
              cursor: 'pointer',
              flex: 1,
              margin: '0 10px',
              border: currentTab === status ? '2px solid #1976d2' : '1px solid #ccc',
            }}
          >
            <CardContent>
              <Typography variant="h6" align="center" style={{ color: '#0f1e35' }}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </Typography>
              <Typography variant="h4" align="center">
                {tabCounts[status] || 0}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<SaveAltIcon />}
        onClick={exportToExcel}
        style={{ marginRight: '10px' }}
      >
        Export to Excel
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {['Created At', 'Name', 'Category','SubCategory', 'Email', 'Phone', 'Portfolio Images', 'Email Verified', 'Description', 'Social Media Linked', 'Actions'].map((header, index) => (
                <TableCell key={index} style={{ fontWeight: 'bold' }}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : currentPageData.length > 0 ? (
              currentPageData.map((row) => (
                <TableRow key={row.accountId}>
                  <TableCell>{row.accountCreatedAt}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{(row.accountCategory)}</TableCell>
                  <TableCell>{(row.accountSubCategory)}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.contact}</TableCell>
                  <TableCell>{row.portfolioImages}</TableCell>
                  <TableCell>{row.isEmailVerified ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{row.accountDescription}</TableCell>
                  <TableCell>{row.socialMediaLinked ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleViewProfile(row.accountId)}
                    >
                      View Profile
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">No records found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
          color="primary"
        />
      </Box>
    </Container>
  );
};

export default WaitlistDashboard;
const convertToTitleCase = (camelCaseString) => {
  if (!camelCaseString || typeof camelCaseString !== 'string') {
    return ''; // Return an empty string if the input is not valid
  }
  return camelCaseString
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add a space before each uppercase letter
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};
