import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { API_BASE_URL } from '../constants/APIConstants';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person'; // Icon for approved ambassadors
import GroupAddIcon from '@mui/icons-material/GroupAdd'; // Icon for new applicants
import BlockIcon from '@mui/icons-material/Block'; // Icon for rejected ambassadors
import { formatDate } from './WaitlistDashboard';
const BrandAmbassadorDashboard = () => {
  const [currentTab, setCurrentTab] = useState('pending');
  const [tableData, setTableData] = useState([]);
  const [tabCounts, setTabCounts] = useState({ pending: 0, approved: 0, rejected: 0 });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = async (status) => {
    setLoading(true);
    const apiUrl = `${API_BASE_URL}/api/data/getAllBrandAmbassadorUsingStatus?status=${status}`;
    console.log('Fetching data from API:', apiUrl);

    try {
      const token = await auth.currentUser.getIdToken();
      console.log('Fetched token:', token);
      const response = await axios.get(apiUrl, { headers: { Authorization: `Bearer ${token}` } });
      console.log('API response:', response.data);

      setTableData(response.data || []);
      setTabCounts((prevCounts) => ({
        ...prevCounts,
        [status]: Array.isArray(response.data) ? response.data.length : 0,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
      setTableData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentTab);
  }, [currentTab]);

  const handleTabChange = (tab) => {
    if (tab !== currentTab) {
      setCurrentTab(tab);
    }
  };

  const handleRefresh = () => {
    fetchData(currentTab);
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#f4f4f8',
      }}
    >
      <Paper
        elevation={3}
        style={{
          width: '100%',
          padding: '30px',
          textAlign: 'center',
          borderRadius: '15px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          variant="h4"
          style={{
            marginBottom: '20px',
            fontWeight: 'bold',
            color: '#292c53',
          }}
        >
          Brand Ambassador Dashboard
        </Typography>
        <Box display="flex" justifyContent="space-around" alignItems="center" marginBottom="20px">
          {['pending', 'approved', 'rejected'].map((status) => (
            <Card
              key={status}
              onClick={() => handleTabChange(status)}
              style={{
                flex: 1,
                margin: '0 10px',
                backgroundColor:
                  currentTab === status
                    ? status === 'pending'
                      ? '#ffe680'
                      : status === 'approved'
                      ? '#a5e39d'
                      : '#ff9999'
                    : '#f9f9f9',
                color: '#3b1212',
                cursor: 'pointer',
                textAlign: 'center',
                border: currentTab === status ? '2px solid #0f1e35' : '1px solid #ddd',
                borderRadius: '10px',
                transition: 'transform 0.3s, background-color 0.3s',
                boxShadow:
                  currentTab === status
                    ? '0 8px 15px rgba(0, 0, 0, 0.2)'
                    : '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.backgroundColor =
                  status === 'pending'
                    ? '#fff8b3'
                    : status === 'approved'
                    ? '#b8ebc6'
                    : '#ffcccc';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.backgroundColor =
                  currentTab === status
                    ? status === 'pending'
                      ? '#ffe680'
                      : status === 'approved'
                      ? '#a5e39d'
                      : '#ff9999'
                    : '#f9f9f9';
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="10px">
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    {status === 'pending' && <GroupAddIcon />}
                    {status === 'approved' && <PersonIcon />}
                    {status === 'rejected' && <BlockIcon />}
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: 'normal',
                      color: '#555',
                    }}
                  >
                    {tabCounts[status]} Ambassadors
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
          <IconButton
            onClick={handleRefresh}
            color="primary"
            style={{
              padding: '10px',
              marginLeft: '10px',
              transition: 'transform 0.2s',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            <RefreshIcon />
            <Typography variant="button" style={{ marginLeft: '5px' }}>
              Refresh
            </Typography>
          </IconButton>
        </Box>
        {loading ? (
          <CircularProgress style={{ color: '#1976d2' }} />
        ) : (
          <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Email</strong></TableCell>
                  <TableCell><strong>Referral Code</strong></TableCell>
                  <TableCell><strong>Applied At</strong></TableCell>
                  <TableCell><strong>Approved At</strong></TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.accountDetails.accountName}</TableCell>
                    <TableCell>{row.accountDetails.accountEmail}</TableCell>
                    <TableCell>{row.referralCode || 'N/A'}</TableCell>
                    <TableCell>{formatDate(row.brandAmbassadorAppliedAt) || 'N/A'}</TableCell>
                    <TableCell>{formatDate(row.brandAmbassadorApprovedAt) || 'N/A'}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </div>
  );
};

export default BrandAmbassadorDashboard;