import React from 'react';
import { Card, CardContent, Typography, Avatar, TextField, Button, Container, Box } from '@mui/material';

const Profile = () => {
  // Example user data; replace this with actual user data fetched from your backend or context.
  const userData = {
    name: 'John Doe',
    email: 'johndoe@example.com',
    profilePic: 'https://via.placeholder.com/150', // Placeholder profile pic
    emailId: '12345',
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        background: `linear-gradient(135deg, #f6d365 0%, #fda085 100%)`, // Soft pastel gradient
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Container style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <Card
          style={{
            width: '400px',
            padding: '20px',
            borderRadius: '15px',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            background: `linear-gradient(135deg, #ffffff, #ffe6cc)`, // Light card gradient
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0px 15px 40px rgba(0, 0, 0, 0.3)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0px 10px 30px rgba(0, 0, 0, 0.2)';
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Avatar
              alt={userData.name}
              src={userData.profilePic}
              style={{
                width: '120px',
                height: '120px',
                border: '3px solid #ffffff',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            />
          </div>
          <CardContent>
            <Typography
              variant="h4"
              style={{
                textAlign: 'center',
                marginBottom: '10px',
                fontWeight: 'bold',
                color: '#333333',
              }}
            >
              {userData.name}
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: 'center',
                marginBottom: '20px',
                color: '#666666',
              }}
            >
              Welcome back to your profile
            </Typography>
            <Box mb={2}>
              <TextField
                label="Name"
                value={userData.name}
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                style={{ backgroundColor: '#ffffff', borderRadius: '5px' }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Email"
                value={userData.email}
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                style={{ backgroundColor: '#ffffff', borderRadius: '5px' }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Email ID"
                value={userData.emailId}
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                style={{ backgroundColor: '#ffffff', borderRadius: '5px' }}
              />
            </Box>
            <Button
              variant="contained"
              style={{
                width: '100%',
                marginTop: '20px',
                backgroundColor: '#ff7f50', // Coral color
                color: '#ffffff',
                padding: '10px 0',
                borderRadius: '25px',
                fontWeight: 'bold',
                fontSize: '16px',
                transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#ff6347'; // Tomato on hover
                e.currentTarget.style.boxShadow = '0px 8px 20px rgba(0, 0, 0, 0.2)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#ff7f50';
                e.currentTarget.style.boxShadow = 'none';
              }}
            >
              Edit Profile
            </Button>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default Profile;