import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Button, AppBar, Toolbar, Grid, Card, CardContent, Snackbar, Alert } from '@mui/material';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaYoutube, FaGlobe } from 'react-icons/fa';
import { API_BASE_URL } from '../constants/APIConstants';

const AccountDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { account, portfolioImages } = location.state || {}; 
  console.log("Account data:", account);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [accountStatus, setAccountStatus] = useState(account.status); // Initialize with the current account status

  useEffect(() => {
    if (account) {
      console.log("Account data:", account);
    } else {
      console.warn("No account data available.");
    }
  }, [account]);

  const handleApprove = async () => {
    try {
      console.log("Approving account:", account);
      const response = await axios.put(
        `${API_BASE_URL}/api/admin/updateWaitlistStatus?accountId=${account.accountId}&status=approved`
      );
      console.log("API Response:", response.data);
      setFeedbackMessage('Account has been approved!');
      setAccountStatus('approved'); // Update status to approved
      setShowFeedback(true);
    } catch (error) {
      console.error("Approval error:", error.response?.data || error.message);
      setFeedbackMessage('Failed to approve account.');
      setShowFeedback(true);
    }
  };
  
  const handleReject = async () => {
    try {
      console.log("Rejecting account:", account);
      const response = await axios.put(
        `${API_BASE_URL}/api/admin/updateWaitlistStatus?accountId=${account.accountId}&status=rejected`
      );
      console.log("API Response:", response.data);
      setFeedbackMessage('Account has been rejected!');
      setAccountStatus('rejected'); // Update status to rejected
      setShowFeedback(true);
    } catch (error) {
      console.error("Rejection error:", error.response?.data || error.message);
      setFeedbackMessage('Failed to reject account.');
      setShowFeedback(true);
    }
  };

  const handleCloseFeedback = () => {
    setShowFeedback(false);
  };

  if (!account) {
    return <div>No account data available.</div>;
  }

  const getSocialMediaIcon = (platform) => {
    switch (platform.toLowerCase()) {
      case 'facebook':
        return <FaFacebook size={24} />;
      case 'twitter':
        return <FaTwitter size={24} />;
      case 'linkedin':
        return <FaLinkedin size={24} />;
      case 'instagram':
        return <FaInstagram size={24} />;
      case 'youtube':
        return <FaYoutube size={24} />;
      case 'website':
        return <FaGlobe size={24} />;
      default:
        return null;
    }
  };


  return (
    <div className="account-details-container" style={{ padding: '20px' }}>
      <div className="logo-navbar-container" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <AppBar position="static" style={{ backgroundColor: '#0f1e35', width: '100%' }}>
          <Toolbar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6" style={{ color: '#ffffff', fontSize: '42px' }}>
              Account Profile
            </Typography>
          </Toolbar>
        </AppBar>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', backgroundColor: '#f0f0f0' }}>
        <Card
          style={{
            marginTop: '50px',
            borderRadius: '30px',
            padding: '15px',
            boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.7)',
            backgroundColor: '#f9f9f9',
            width: '75%',
            position: 'relative',
            overflow: 'auto',
          }}
        >
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  src={account.accountProfileURL}
                  alt="Profile"
                  style={{
                    width: '200px',
                    height: '200px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <Card
                  style={{
                    padding: '10px',
                    backgroundColor: '#f0f8ff',
                    color: '#000000',
                    borderRadius: '15px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                    width: '75%',
                  }}
                >
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    {account.name}
                  </Typography>
                  <Typography variant="subtitle1" style={{ fontSize: '18px' }}>Email: {account.email}</Typography>
                  <Typography variant="subtitle1" style={{ fontSize: '18px' }}>Email Verified: {account.isEmailVerified ? 'Verified' : 'Not Verified'}</Typography>
                  <Typography variant="subtitle1" style={{ fontSize: '18px' }}>Phone: {account.contact || 'Not Provided'}</Typography>
                  <Typography variant="subtitle1" style={{ fontSize: '18px' }}>Address: {account.accountCity || 'Address Not Provided'}</Typography>
                  <Typography variant="subtitle1" style={{ fontSize: '18px' }}>DOB: {account.dob || 'Not Provided'}</Typography>
                  <Typography variant="subtitle1" style={{ fontSize: '18px' }}>Description: {account.accountDescription || 'No Description Available'}</Typography>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card
                  style={{
                    padding: '15px',
                    backgroundColor: '#ffe6b3',
                    color: '#000000',
                    borderRadius: '15px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                    width: '100%',
                  }}
                >
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Portfolio Images
                  </Typography>
                  <Grid container spacing={2} style={{ marginTop: '10px' }}>
                    {account.portfolioCollections && account.portfolioCollections.length > 0 ? (
                      account.portfolioCollections.map((collection, collectionIndex) => (
                        collection.portfolioDocuments.map((document, documentIndex) => (
                          <Grid item xs={12} sm={6} md={4} key={`${collectionIndex}-${documentIndex}`}>
                            <div style={{ position: 'relative' }}>
                              <img
                                src={document.imageURL}
                                alt={`Portfolio ${documentIndex + 1}`}
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  borderRadius: '10px',
                                  objectFit: 'cover',
                                  transition: 'transform 0.2s',
                                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                              />
                            </div>
                          </Grid>
                        ))
                      ))
                    ) : (
                      <Typography>No portfolio images available.</Typography>
                    )}
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Card
                  style={{
                    padding: '10px',
                    backgroundColor: '#ffe6b3',
                    color: '#000000',
                    borderRadius: '15px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                  }}
                >
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>Social Media</Typography>
                  {account.socialLinks && Object.values(account.socialLinks).some((url) => url) ? (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
                      {Object.entries(account.socialLinks).map(([platform, url]) => (
                        url && (
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={platform}
                            style={{ color: '#0f1e35', textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '5px' }}
                          >
                            {getSocialMediaIcon(platform)}
                            <Typography variant="body2">{platform.charAt(0).toUpperCase() + platform.slice(1)}</Typography>
                          </a>
                        )
                      ))}
                    </div>
                  ) : (
                    <Typography>No social media links available</Typography>
                  )}
                </Card>
              </Grid>

              {/* Account Status */}
              <Grid item xs={12} sm={6}>
                <Card
                  style={{
                    padding: '10px',
                    backgroundColor: '#e6e6e6',
                    color: 'black',
                    borderRadius: '15px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                  }}
                >
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>Account Action</Typography>
                  {accountStatus === 'approved' ? (
                    <Typography variant="body1" color="green">Account Approved</Typography>
                  ) : accountStatus === 'rejected' ? (
                    <Typography variant="body1" color="red">Account Rejected</Typography>
                  ) : (
                    <Grid container spacing={2} style={{ marginTop: '10px' }}>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={handleApprove}
                          style={{ width: '100%' }}
                          disabled={accountStatus === 'approved' || accountStatus === 'rejected'}
                        >
                          Approve
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleReject}
                          style={{ width: '100%' }}
                          disabled={accountStatus === 'approved' || accountStatus === 'rejected'}
                        >
                          Reject
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <Snackbar open={showFeedback} autoHideDuration={6000} onClose={handleCloseFeedback}>
        <Alert onClose={handleCloseFeedback} severity="info" sx={{ width: '100%' }}>
          {feedbackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AccountDetails;
